import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NoPathFound} from './angular/component/not-found/app.comonent';

const routes: Routes = [
  {
    path: '**',
    component: NoPathFound,
    pathMatch: 'full',
  },
  {path: '/Users/my-profile'},
  {path: '/Login'},
  {path: '/Logout'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
