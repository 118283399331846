<div [ngClass]="isLoggedIn ? 'wrap active-right-panel active-top-panel' : 'wrap active-right-panel active-top-panel guest'">
  <pc-header [isLoggedIn]="isLoggedIn"></pc-header>
  <!--Old Angularjs-->
  <div
    data-ng-class="page.bodyClass()"
    data-ui-view="page"
    data-ng-show="!page.loading.user"
    style="flex: 1"
  ></div>
  <!--Old Angularjs-->
  <footer [isLoggedIn]="isLoggedIn"></footer>
</div>
