import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AuthService {
  public isAuthenticated: BehaviorSubject<boolean | string | null> =
    new BehaviorSubject(false);

  constructor(public jwtHelper: JwtHelperService, public router: Router) {
    this.isAuthenticated.next(this.isLoggedIn());
  }

  public isLoggedIn() {
    const token = this.getToken();
    const leisureToken = this.getLeisureToken().replaceAll('"', '');

    return token !== 'null' && leisureToken !== 'null';
  }

  getToken() {
    const token = localStorage.getItem('partnerchoice_api_token');
    if (token !== 'null' && this.jwtHelper.isTokenExpired(token)) {
      this.logout();
    }

    return token;
  }

  getLeisureToken() {
    return localStorage.getItem('AuthToken');
  }

  async logout() {
    await localStorage.setItem('partnerchoice_api_token', null);
    await localStorage.setItem('partnerchoice_api_refresh_token', null);
    await localStorage.setItem('AuthToken', null);
    this.router.navigate(['/Login']);
    this.isAuthenticated.next(this.isLoggedIn());
  }
}

export function tokenGetter() {
  return localStorage.getItem('partnerchoice_api_token');
}
