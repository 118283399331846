import {Component, Input} from '@angular/core';
import {CategoryService} from '../../../services/category-service';

@Component({
  selector: 'category-menu',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class CategoryMenuComponent {
  @Input() openCatMenu: boolean;
  @Input() isLoggedIn: boolean;
  @Input() gazetteUrl: string;
  public categories;

  constructor(public categoryService: CategoryService) {
    this.categoryService.load().then(data => (this.categories = data));
  }

}
