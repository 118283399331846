import {Component} from '@angular/core';
import {BehaviorSubject, Subscription as RxSubscription} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public isLoggedIn: string | boolean | null = true;
  private Subscriptions: RxSubscription[] = [];

  constructor(public authService: AuthService) {}

  ngOnInit() {
    this.Subscriptions.push(
      this.authService.isAuthenticated.subscribe(authenticated => {
        this.isLoggedIn = authenticated;
      })
    );
  }
}
