<div id="mobileHamburgerModal" class="modal left-panel cat-menu-active">
  <div class="menu-container modal">
    <li class="social-icons mobile">
      <a href="https://www.partnerchoice.co.uk/listing/9216/partnerchoice-app"
        ><span class="fa fa-fw fa-lg fa-brands fa-apple"></span
      ></a>
      <a href="https://www.partnerchoice.co.uk/listing/9216/partnerchoice-app"
        ><span class="fa fa-fw fa-lg fa-brands fa-android"></span
      ></a>
      <a [href]="gazetteUrl" target="blank" class="gazette"
        ><img
          class="fa fa-fw fa-lg"
          src="/app/assets/img/homepage/Gazette_Logo.png"
      /></a>
    </li>
    <nav>
      <ul class="menu">
        <span *ngFor="let item of categories">
          <li *ngIf="item.display_in_menu == 1">
            <a href="/category/{{ item.slug }}" title="{{ item.title }}"
              ><span class="fa {{ item.font_icon }}"></span>{{ item.title }}</a
            >
            <ul *ngIf="item.children">
              <li *ngFor="let child; in: item.children">
                <a
                  href="/category/{{ child.slug }}"
                  title="{{ child.title }}"
                  >{{ child.title }}</a
                >
              </li>
            </ul>
          </li>
        </span>
        <div class="help" *ngIf="!isLoggedIn">
          <nav>
            <ul>
              <li>
                <a href="https://jlpleisurebenefits.zendesk.com/home" title=""
                  >Help?</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </ul>
    </nav>
    <div *ngIf="isLoggedIn" class="extra-categories">
      <p>Explore how we can help you</p>
      <div class="box-container">
        <div class="category-box">
          <a href="/listing/8425/financial-wellbeing">
            <img alt="" src="/app/assets/img/categories/finance.png" />
            <div class="text">Financial Wellbeing</div>
          </a>
        </div>
        <div class="category-box">
          <a href="/listing/7956/health-wellbeing-hub">
            <img alt="" src="/app/assets/img/categories/health.png" />
            <div class="text">Health Hub</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
